<template>
	<div id="Recharge">
		<div class="recharge-title">
			<div class="icon-return" @click="$router.back()"></div>
			兑换金额
		</div>
		<div class="integral-desc">
			每（{{ integral_to_bean_rate.integral }}）积分，可兑换（{{ integral_to_bean_rate.bean }}）金额<br>
			<span>剩余积分：{{ User.integral || 0 }}</span>
		</div>
		<div class="integral-con">
			<div class="item">
				<span>积分</span>
				<div class="input"><input type="text" placeholder="请输入" v-model="integral"></div>
			</div>
			<img src="~@/assets/img/integral-img.png" alt="" class="integral-img">
			<div class="item">
				<span>金额</span>
				<div class="input"><input type="text" placeholder="请输入" v-model="bean" readonly></div>
			</div>
		</div>
		<div class="recharge-btn" @click="toRechange">
			立即兑换
		</div>
		
		<div class="recharge-title">
			兑换记录
		</div>
		<div class="recharge-record">
			<div class="record_item th">
				<p>消耗积分</p>
				<p>获得金额</p>
				<p>兑换时间</p>
			</div>
			<div class="record_item" v-for="(item,index) in RecordList" :key="index">
				<p>{{item.integral}}</p>
				<p>{{item.bean}}</p>
				<p>{{item.created_at}}</p>
			</div>
			<div class="page-con">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" @current-change="handleCurrentChange" :pager-count='5' prev-text="上一页" next-text="下一页" small>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ShopRecords,
		integralRecords,
		integralExchange
	} from '@/network/api.js'
	import {
		mapState,
		mapActions
	} from 'vuex'
	export default {
		name: 'Integral',
		data() {
			return {
				active: 0, //tab分栏
				BeanList: [], //金豆列表
				pay_way: '1', //支付类别,1支付宝,2微信
				CardCode:'',//卡密兑换码
				RecordList: [], //充值记录

				total:0,//总数据数量
				per_page:0,//一页数据
				page_size:1,//页码
				selectId: '',
				qr_code: '',
				qr_show: false,
				payHtml: '',
				selectType: 6,
				G_Show: false,
				content: `1.我已满18岁具备完全行为能力，且当前账号为本人操作。

2.本人仅在经济承受范围内消费，且将为消费行为承担后果，继续充值即代表同意《用户协议》和《隐私协议》。

3.购买虚拟物品成功后不可退还或换货，知晓取回的虚拟道具将在5min-24h内发货，且获取的虚拟道具禁止转售。

4.网站玩法以娱乐为主，请保持理性消费。

5.网站充值的金额用于购买开启饰品宝箱所需碎片。
`,
				integral_to_bean_rate: {
					integral: 0,
					bean: 0
				},
				integral: '',
				bean: 0.00
			}
		},
		created() {
			this.GetRechargeRecord()
		},
		computed: {
			...mapState(['User', 'publicConfig']),
		},
		methods: {
			...mapActions([
				'Refresh'
			]),
			//金豆列表
			toRechange() {
				integralExchange({integral: this.integral}).then((res) => {
					this.$notify({
						type: 'success',
						message: '兑换成功'
					})
					this.Refresh()
				})
			},
			// 支付(携带参数跳转页面，防止拦截)
			
			//充值记录
			GetRechargeRecord() {
				integralRecords(this.page_size).then((res) => {
					// console.log(res.data.data)
					this.total = res.data.data.total
					this.per_page = res.data.data.per_page
					this.RecordList = res.data.data.records
				})
			},
			//充值记录分页
			handleCurrentChange(val) {
				// console.log(val)
				this.page_size = val
				this.GetRechargeRecord()
			}
		},

		watch: {
			active(v) {
				// console.log(v)
				this.pay_way = v + 1
				// console.log(this.pay_way)
			},
			publicConfig(val){
				try {
					const integral_to_bean_rate = JSON.parse(val.integral_to_bean_rate)
					this.integral_to_bean_rate = {
						integral: integral_to_bean_rate.integral,
						bean: integral_to_bean_rate.bean
					}
				} catch (error) {
					this.integral_to_bean_rate = {
						integral: 0,
						bean: 0
					}
				}
			},
			integral(val){
				if (val) {
					this.bean = (this.integral * this.integral_to_bean_rate.bean / this.integral_to_bean_rate.integral).toFixed(2)
				} else {
					this.bean = 0
				}
			}
		}

	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.recharge-title{
		position: relative;
		border-top: 1px solid #D9D9D9;
		border-bottom: 1px solid #D9D9D9;
		height: 40px;
		@include HScenter;
		.icon-return{
			position: absolute;
			top: 50%;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
			transform: translateY(-50%);
		}
		span{
			font-size: 12px;
			color: #FFB800;
		}
		&:before{
			content: '';
			position: absolute;
			left: 1px;
			top: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
		&:after{
			content: '';
			position: absolute;
			right: 1px;
			bottom: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
	}
	.recharge-list{
		width: 330px;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		gap: 12px 15px;
		padding-top: 20px;
		.item{
			width: 100px;
			height: 42px;
			@include HScenter;
			background: #1D1D28;
			font-size: 17px;
			font-weight: bold;
			box-sizing: border-box;
			cursor: pointer;
			.icon-coins{
				width: 20px;
				height: 20px;
				flex-shrink: 0;
				background-image: url(~@/assets/img/coins-yellow.svg);
				background-size: 100% 100%;
				margin-right: 6px;
			}
			&.active{
				border: 1px solid #FFB800;
				background: rgba(255, 184, 0, 0.15);
				color: #FFB800;
				.icon-coins{
					// background-image: url(~@/assets/img/icon-coins-big-active.png);
				}
			}
		}
	}
	.recharge-type{
		@include Scenter;
		width: 330px;
		margin: 18px auto 7px;
		background: #1D1D28;
		height: 40px;
		padding: 0 16px;
		.right{
			@include Scenter;
			margin-left: auto;
			img{
				width: 14px;
				height: 14px;
				margin-right: 4px;
			}
		}
	}
	.recharge-tit{
		font-size: 12px;
		width: 330px;
		margin: 0 auto;
	}
	.recharge-btn{
		width: 275px;
		height: 56px;
		background-image: url(~@/assets/img/open-btn-bg.png);
		background-size: 100% 100%;
		margin: 30px auto 55px;
		@include HScenter;
		font-size: 17px;
		cursor: pointer;
	}
	.recharge-record{
		.record_item{
			display: flex;
			overflow: hidden;
			color: #ddd;
			margin-top: 14px;
			&.th{
				color: #FFB800;
			}
			p{
				padding-left: 20px;
				box-sizing: border-box;
				flex-shrink: 0;
				&:nth-child(1){
					width: 30%;
				}
				&:nth-child(2){
					width: 30%;
				}
				&:nth-child(3){
					width: 40%;
				}
				&:nth-child(4){
					flex: 1;
				}
			}
		}
	}
	.page-con{
		padding: 20px 0;
	}
	.qr-code{
		width: 200px;
		height: 200px;
		margin: 0 auto;
	}
	.qr-tit{
		text-align: center;
		font-size: 12px;
		color: #bbb;
		margin-top: 12px;
	}
	.recharge-type-list{
		@include Scenter;
		gap: 28px;
		margin: 15px auto 0px;
		width: 330px;
		.item{
			line-height: 20px;
			cursor: pointer;
			&.active{
				position: relative;
				&::before{
					content: '';
					position: absolute;
					left: 10%;
					right: 10%;
					bottom: -5px;
					height: 2px;
					background: #FFB800;
				}
			}
		}
	}
	.agree-btn{
		width: 257px;
		height: 51px;
		background-image: url(~@/assets/img/agree-btn-bg.png);
		background-size: 100% 100%;
		@include HScenter;
		margin: 10px auto 0;
	}
	.integral-desc{
		text-align: center;
		color: #999999;
		margin: 32px 0 20px;
		span{
			color: #FFB800;
			margin-top: 8px;
			display: block;
		}
	}
	.integral-con{
		@include HScenter;
		gap: 10px;
		.item{
			span{
				background: var(--LOGO, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			.input{
				margin-top: 7px;
				width: 94px;
				height: 36px;
				border: 1px solid;
				border-image: linear-gradient(to bottom, #448EFD, #44FDE7);
				border-image-slice: 1;
				background: linear-gradient(180deg, #022A44 0%, rgba(29, 29, 40, 0.00) 100%);
				input{
					width: 100%;
					height: 100%;
					text-align: center;
				}
			}
		}
		.integral-img{
			width: 26px;
			margin-top: 25px;
		}
	}
</style>
